'use client'

import NextLink, { LinkProps } from 'next/link'
import { PropsWithChildren } from 'react'

import { useStoreContext } from '@/providers/store/store-context'
import { addSlashToUaHomepagePathname, getUrl } from '@/common/utils/url-utils'

interface Props extends LinkProps {
  href: URL | string
  id?: string
  title?: string
  className?: string
  keepUrl?: boolean
  /**
   * @deprecated don't use "baseUrl"
   */
  baseUrl?: string // TODO: cleanup baseUrl prop drilling
}

/**
 * - "keepUrl" is set -> `use "href" as is (exception for "ua" homepage)`
 * - "keepUrl" is not set -> `use "baseUrl" to generate origin for "href"`
 *
 * TODO:
 * - cleanup unused utils from "@/common/utils/url-utils"
 * - verify each Link instance used in repo that is correctly called
 * - verify useRouter usage
 */
export function Link({
  href,
  keepUrl = false,
  baseUrl: _baseUrl,
  ...props
}: Props & PropsWithChildren) {
  const {
    storeConfig: { baseUrl },
  } = useStoreContext()
  const url = keepUrl ? href : getUrl(baseUrl, href.toString())
  const urlWithSlashForUaPathname = addSlashToUaHomepagePathname(url)

  return (
    <NextLink
      data-testid="link"
      prefetch={false}
      href={urlWithSlashForUaPathname}
      {...props}
    />
  )
}
